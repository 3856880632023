<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :items="groups"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer">
            {{ item.code }}
          </td>
          <td v-if="hidden.name">{{ item.name }}</td>
        </tr>
      </template>

      <button
        v-if="$auth.hasRole(`group:${groupType}:add`)"
        type="button"
        class="btn btn-link text-success"
        slot="action" @click="toDetail(0)">
        เพิ่ม
      </button>

    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_GROUP } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    groupType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Group${this.$form.capitalize(this.groupType)}Detail`,
      rKey: `Group${this.$form.capitalize(this.groupType)}List`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true, sort: true},
        {text: 'ชื่อ', value: 'name', filter: true, sort: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      groups: [],
    }
  },
  apollo: {
    groups: {
      query () {
        return LIST_GROUP(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          groupType: this.groupType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      return filter
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {groupId: id}
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
